a {
	font-style: normal;
	text-decoration: underline;
	color: theme(link, dark);
	&:hover,
	&:focus {
		color: theme(link, base);
		text-decoration: none;
	}
	&:focus {
		outline: 1px solid lighten($color-secondary, 25%);
	}
}

a.img-link {
	text-decoration: none;
}


.phone {
	text-decoration: none;
	white-space: nowrap;
	cursor: default;
	color: $color-phone;
	&:hover, &:active, &:focus {
		text-decoration: none;
		color: $color-phone;
		outline: none;
		cursor: default;
	}
}

	.phone.mask {
		color: $color-text;
		&:hover {
			outline: none;
			color: $color-text;
		}
	}

