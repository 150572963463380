.block_reviews {
	padding-bottom: 30px;
	font-family: $font-family-sans-serif;
	border-top: 5px solid #ccc;
	border-bottom: 5px solid #ccc;
	background: linear-gradient(-55deg, #f4f4f4 50%, #ededed 50%);
}

	.reviews_main {

		@include make-flex;
		.review {
			padding: 30px 15px;
			background-size: auto 100%;
			background-repeat: no-repeat;
			background-position: left center;

			&.review-1 {
				padding-left: 60px;
				background-image: url("/_/images/icons/quote-left.svg");
			}

			&.review-2 {
				margin-left: auto;
				padding-right: 60px;
				text-align: right;
				background-image: url("/_/images/icons/quote-right.svg");
				background-position: right center;
			}

		}

		@include media-breakpoint-up(md) {
			.review {
				@include make-col(10);
				&.review-2 {
					@include make-col(9);
					margin-top: -75px;
				}
			}
		}

		@include media-breakpoint-up(lg) {
			padding: 0 60px;
			.review {
				@include make-col(8);
				&.review-2 {
					@include make-col(7);
					margin-top: -90px;
				}
			}
		}

		@include media-breakpoint-up(xl) {
			padding: 0 90px;
			.review {
				@include make-col(7);
				&.review-2 {
					@include make-col(6);
					margin-top: -110px;
				}
			}
		}

	}

	.reviews_more {
		img {
			margin: 0 auto;
			width: 230px;
		}
		a {
			display: inline-block;
			@include transition(transform ease-in-out 200ms);
			@include transform(scale(0.9));
			&:hover {
				@include transition(transform ease-in-out 200ms);
				@include transform(scale(1));
			}
		}
		@include media-breakpoint-up(md) {
			img { margin-top: -50px; }
		}
	}