.page_title {

	padding: 30px 15px;
	background-color: theme(secondary, base);
	color: $white;

	.page-title {
		margin: 0;
	}

	@include media-breakpoint-up(md) {
		padding-top: 120px;
		background-color: theme(primary, base);
		@include gdfancy-bg(theme(primary, base));
	}

	@include media-breakpoint-up(lg) {}

}

