	.blog_roll_item {
		padding: 30px;
		&:nth-child(odd) {
			background-color: rgba($white,0.8);
		}

	}

			.roll-item-title {
				margin-top: 0;
				max-width: 640px;
				@extend %h4;
				font-weight: $heading-weight-normal;
				line-height: 1em;
				a {
					@include transition(all ease-in-out 300ms);
					text-decoration: none;
					&:hover {
						@include transition(all ease-in-out 300ms);
						color: theme(primary, base);
					}
				}
			}

		.roll_item_content {
			max-width: 640px;
		}


		.posts_nav {
			border-top: 1px solid #aaa;
			padding-top: 15px;
			@include make-flex();
			a {
				order: 2;
				display: inline-block;
			    padding: 12px 15px;
			    font-family: $font-family-headings;
			    font-weight: $body-weight-bold;
			    line-height: 1em;
			    text-decoration: none;
			    text-transform: uppercase;
				cursor: pointer;
				border-radius: 3px;
				border: 1px solid theme(secondary, dark);
			    background-color: theme(secondary, base);
			    color: $white;
			    @include transition(all ease-in-out 200ms);

				small { display: block; line-height: 1em }

				&:hover {
					@include transition(all ease-in-out 200ms);
			        background-color: theme(secondary, light);
			        color: $white;
				}

				&:focus { outline: 4px solid rgba(0,0,0,0.25); }				

			}

			a+a { order: 1; margin-right: 30px; }

		}