.call { line-height: 1em; }

	.phone {
		font-family: $font-family-sans-serif;
		&.mask { color: theme(text,base); }
	}

		.contact-phone,
		.banner-phone,
		.info-phone {
			@include font-size(30);
			letter-spacing: -0.0325em;
			font-weight: $body-weight-bolder;
		}


		.contact-title {
			display: block;
			margin-bottom: 6px;
			font-family: $font-family-sans-serif;
			//font-size: inherit;
			font-weight: $body-weight-bold;
			letter-spacing: -0.0275em;
			line-height: 1em;
		}