
.faq_item {

	counter-increment: section;
	&:not(:last-child) { border-bottom: 1px solid #ccc; }

	.faq_title {
		h2 {
			margin: 0 0 0 60px;
			padding: 15px;
			font-size: 1.2rem;
			text-transform: none;
			font-weight: $heading-weight-normal;
			letter-spacing: 0;
			&:hover {
				color: theme(secondary, base);
				cursor: pointer;
			}
			&:before {
				display: inline-block;
				margin: 0 15px 0 -61px;
				width: 48px;
				font-weight: $heading-weight-bold;
				content: counter(section) ". ";
			}
		}
	}

		&.open .faq_title {
			h2 { font-weight: $heading-weight-bold; color: $white !important; }
			background-image: linear-gradient(to right, theme(secondary, base) 60px, theme(primary, base) 60px);
		}

	.faq_body {
		padding: 20px;
		background-color: $white;
		> *:last-child { margin-bottom: 0; }
		p, ul, ol { margin-bottom: 10px; line-height: 1.4em }
		ul, ol {
			li:not(:last-child) { margin-bottom: 5px; }
		}
	}

}