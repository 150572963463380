.widget.recent-posts-menu {

	padding: 0;
	border: 2px solid theme(highlight, base);
	text-shadow: none;

	.widget-title {
		margin: 0;
		padding: 15px;
		background-color: theme(highlight, base);
		font-weight: $heading-weight-bold;
	}

	ul {
		@extend %list_unstyled;
		margin: 0;
		line-height: 1em;

		li {
			margin-bottom: 0;
			padding: 5px;
		}

		li:not(:last-child) {
			border-bottom: 1px dotted theme(highlight, base);
		}

		a {
			display: block;
			padding: 10px;
			@include font-size(15);
			font-weight: $body-weight-bold;
			text-decoration: none;
			color: theme(secondary, base);
			&:hover {
				color: theme(primary, base);
				//color: $white;
			}
		}

	}

}