.main {
	h3, .h3 { color: $color-secondary; }
}
	
	.sub_menu_wrapper {
		@include media-breakpoint-up(md) {
			float: right;
			margin: 0 0 15px 15px;
			.widget {
				display: inline-block;
				width: auto;
			}
		}
	}