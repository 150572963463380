.slideshow {

    .slick-prev,
    .slick-next {
        z-index: 1;
        top: 0;
        bottom: 0;
        height: 100%;
        width: 44px;
        background-color: transparent;
        background-position: center center;
        background-size: 14px;
        opacity: 0.3;
        &:hover {
            background-size: 18px;
            opacity: 1;
        }
    }

    .slide_caption {
        line-height: 1em;
        padding: 20px 15px;
        text-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
        background-color: theme(text, dark);
        color: $white;
    }

        .caption-title {
            margin: 0 0 0.5em;
            @include font-size(18);
            font-weight: $heading-weight-bolder;
            line-height: 1em;
        }

        .caption-text {
            margin: 0;
            @include font-size(15);
            line-height: 1.2em;
        }

        .caption-text+.caption-text { margin-top: 0.5em }


}