.widget_menu {

	max-width: none;
	padding: 20px;
	text-shadow: none;
	border: 2px solid theme(primary, dark);
	background-color: theme(primary, base);

	.widget-title {
		font-family: $font-family-headings;
		color: $white;
	}

	.menu_item:not(:last-child) { border-bottom: 1px solid rgba(black,0.3); }
	.menu_item:not(:first-child) { border-top: 1px solid rgba($white, 0.4); }

	.menu_item.active {
		.menu_link {
			background-color: theme(secondary, base);
			color: $white;
		}
	}

	.menu_link {
		//padding: 10px;
		@include font-size(15);
		font-weight: $body-weight-bold;
		color: $white;
		background-image: linear-gradient(
			to right,
			theme(secondary, base) 50%,
			transparent 50%
		);
		background-size: 202% 100%;
		background-position: top right;
		&:hover {
			background-position: top left;
		}
	}

}