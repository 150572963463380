.form_quick {

	padding: 15px;
	border-radius: 3px;
	border: 2px solid #ccc;
	background-color: $white;

	.sidebar & {

		@include media-breakpoint-up(xl) {
			.form_spam {
				height: 61px;
				.g-recaptcha {
					top: -8px;
					left: -32px;
					@include transform(scale(0.79));
				}
			}			
		}

	}

}