.contact_map {
	padding: 5px;
	border: 1px solid #ccc;
	background-color: $white;

	@include media-breakpoint-up(lg) {
		.embed_responsive { padding-bottom: 251% !important; }
	}

	@include media-breakpoint-up(xl) {
		.embed_responsive { padding-bottom: 207% !important; }
	}

}

.service_area_map {
	padding: 5px;
	border: 1px solid #ccc;
	background-color: $white;
}