.block_welcome {

	padding: 35px 15px;
	background-color: $white;

	.list {
		max-width: 780px;
		margin: 0 auto;
	}

	.welcome-badge {
		width: 150px;
	}

	@include media-breakpoint-up(lg) {
		.wrap { @include make-col(10); }
		.list { clear: both; }
		.badge-left { float: left; margin: 0 20px 10px -50px; }
		.badge-right { float: right; margin: 0 -50px 10px 20px; }
	}

	@include media-breakpoint-up(xl) {
		.wrap { @include make-col(9); }
		.badge-left,
		.badge-right { margin-top: 0; }
	}

}