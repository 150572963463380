.company_info {

	padding: 30px 15px;

	@include media-breakpoint-up(md) {

		@include make-flex;

		.wrap {
			width: 50%;
			padding: 0 15px;
		}

		.company_associations {
			width: 100%;
			text-align: center;
		}

	}

	@include media-breakpoint-up(lg) {
		.wrap,
		.company_associations {
			width: auto;
		}
	}

	@include media-breakpoint-up(xl) {
		padding: 15px;
		.wrap { margin: 0; }
		.company_associations { margin-left: auto; }
	}

}

	.company_contact {
		@include make-flex;
		flex-wrap: nowrap;
		align-items: flex-start;
		margin-bottom: 15px;
		p {
			width: auto;
			margin: 0;
			padding: 5px 0 5px 10px;
			@include font-size(18);
			line-height: 1em;
			border-left: 5px solid theme(secondary, base);
			
			&:first-child { margin: 0 15px 0 0; }

			strong { display: block; }

			.phone {
				@include font-size(21);
				color: $color-text;
			}
		}
	}

	.company_license {
		margin-bottom: 15px;
		ul.list-license {
			margin: 0;
			padding: 5px 0 5px 10px;
			line-height: 1em;
			border-left: 5px solid theme(secondary, base);
			> li:first-child {
				@include font-size(18);
			}
			> li:not(:first-child) {
				@include font-size(16);
				font-family: $font-family-sans-serif;
				letter-spacing: -0.0325em;
			}
		}
	}

	.company_associations {
		line-height: 0;
		ul.list-associations {
			margin-bottom: 0;
			> li {
				display: inline-block;
				margin-bottom: 0;
				&:not(:last-child) { margin-right: 10px; }
			}
		}
		img { max-height: 70px; }
	}



.site_info {
	padding: 20px 15px 15px;
	font-family: $font-family-sans-serif;
	border-top: 5px solid #fff;
	background-color: theme(primary, dark);
	color: $white;
	a { color: $white; }
	@include media-breakpoint-up(md) {
		.copyright-list {
			margin-bottom: 0;
			text-align: center;
			li { display: inline-block; }
			li:not(:last-child) { margin-right: 20px; }
		}
	}
}