$nav-mobile-bg: 			theme(primary, base) !default;
$mobile-link-bg: 			transparent !default;
$mobile-link-color:			$white !default;
$mobile-hover-bg: 			theme(highlight, base) !default;
$mobile-hover-color: 		$white !default;
$mobile-active-bg: 			$white !default;
$mobile-active-color: 		theme(primary, base) !default;

@mixin offcanvas {
	display: none;
	&.in { display: block; }
}

.nav_mobile {

	@include offcanvas;
	position: fixed;
	z-index: 999999;
	top: 0; right: auto;
	bottom: 0; left: 0;
	overflow-y: auto;
	width: 90%;
	max-width: 320px;
	height: 100%;
	padding: 30px 15px;
	border-right: 3px solid $white;
	box-shadow: 2px 0 5px 0 rgba(0,0,0,0.5);
	background-color: $nav-mobile-bg;
	color: $white;

	@include media-breakpoint-up(md) { display: none !important; }

	h4 {
		font-size: 1.2em;
		font-weight: $heading-weight-bolder;
		text-transform: uppercase;
	}

	hr {
		margin: 30px 0;
		background-color: $white;
	}

	.nav_menu > .menu_item:not(:last-child) { border-bottom: 1px solid rgba(0,0,0,0.35); }
	.nav_menu > .menu_item:not(:first-child) { border-top: 1px solid rgba(255,255,255,0.5); }

	.nav_menu > .menu_item {

		&.active .menu_link {
			background-color: $mobile-active-bg;
			color: $mobile-active-color;
			&:hover, &:focus {
				outline: none;
				background-color: $mobile-active-bg;
				color: $mobile-active-color;
			}
		}

		&.open {
			> .menu_link { background-color: $mobile-hover-bg; }
			.dropdown_menu { background-color: $mobile-hover-bg; }
		}

	}	

	.caret {
		background-image: url("/_/images/icons/caret-white.png");
	}

		.nav_menu > .menu_item > .menu_link {
			padding: 12px 10px;
			font-weight: $body-weight-bold;
			background-color: $mobile-link-bg;
			color: $mobile-link-color;
			&:hover, &:focus {
				outline: none;
				background-color: $mobile-hover-bg;
				color: $mobile-hover-color;
			}
		}

	.dropdown_menu {
		padding: 0;

		.menu_item:not(:last-child) { border-bottom: 1px solid rgba($white,0.5); }
		.menu_item:not(:first-child) { border-top: black; }

			.menu_link {
				background-color: transparent;
				color: $white;
				&:hover, &:focus {
					outline: none;
					background-color: theme(secondary, base);
					color: $white;					
				}				
			}

	}

	.mobile-toggle {

		font-size: 1.2em;
		color: #fff;
		text-decoration: none;

		img {
			display: inline-block;
			width: 0.8em;
			margin-right: 10px;
		}

	}

}

