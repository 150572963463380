@mixin alert($bg: #ddd, $color: $color-text) {
	border: 1px solid $bg;
	background-color: lighten($bg, 25%);
	color: $color;
	.alert-title { color: darken($bg, 15%); }
}

.alert {
	
	@include alert();
	padding: 1em;
	border-radius: 3px;
	
	.button_close {
		float: right;
	}

}

	.alert-primary { @include alert($color-primary); }
	.alert-secondary { @include alert($color-secondary); }
	.alert-highlight { @include alert($color-highlight); }
	.alert-accent { @include alert($color-accent); }
	.alert-success { @include alert($color-success); }
	.alert-danger { @include alert($color-danger); }
	.alert-info { @include alert($color-info); }
	.alert-warning { @include alert($color-warning); }
	.alert-link { @include alert($color-link); }

	.alert-title {
		margin-top: 0;
		margin-right: 34px;
		font-family: $font-family-body;
		font-weight: $body-weight-bold;
		letter-spacing: -0.0325em;
	}

.block.block_alert {

	padding: 15px;
	border-radius: 0;
	border: none;
	border-bottom: 3px solid #fff;
	font-family: $font-family-headings;
	line-height: 1.325;


	.alert_title {
		strong {
			font-weight: $body-weight-bolder;
			letter-spacing: -0.0325em;
			white-space: nowrap;
		}
	}

	a {
		font-weight: $body-weight-bold;
		color: $color-text;
	}

	@include media-breakpoint-up(md) {
		.alert_wrapper {
			@include make-flex;
			flex-wrap: nowrap;
			align-items: center;
			justify-content: center;
			.alert_title,
			.alert_text { display: inline-block; }
			.alert_title { padding: 0 45px 0 0; min-width: 240px !important; }
		}
	}
	
	@include media-breakpoint-up(lg) {
		.row { justify-content: flex-start; }
		.wrap { @include make-col(10,12); }
	}

}