.logo {
	display: block;
	margin: 0 auto;
	width: 100%;
	line-height: 1em;
}

	.banner-logo {
		max-width: 270px;
		margin: 0 auto 5px;
		padding: 5px;
		text-align: center;
		text-decoration: none;
		@include media-breakpoint-up(md) {
			margin: 0;
			max-width: 240px;
		}
	}

	.banner_best { 
		text-align: center; }

	.banner-best {
		display: inline-block;
		margin-bottom: 10px;
		padding: 5px;
		text-decoration: none;
		line-height: 1em;
		letter-spacing: -0.0325em;
		color: theme(text, base) !important;
		vertical-align: middle;
		> * { vertical-align: middle; }
		.icon-g { margin-right: 4px; }
		b {
			display: block;
			margin-top: 4px;
			@include font-size(16);
			font-family: $font-family-sans-serif;
		}
		@include media-breakpoint-up(md) {
			float: right;
		}
	}

	.icon-stars { fill: $color-review; }