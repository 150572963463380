.slider_jumbo {
    
    line-height: 0;

    * { line-height: 0; }

    .slick-prev,
    .slick-next { display: none !important; }

    .slide_media {
        max-width: 1400px;
        margin: 0 auto;
        background-position: center center;
        background-size: cover;     
		img {
			width: 100%;
		}
    }

    @include media-breakpoint-up(md) {

		.slide { background-size: cover; }
		
		.slide_media {
			background-size: cover;
			background-position: center center;
			img { opacity: 0; }
		}

        .slide_1 .slide_media { background-image: url("/_/images/slides/jumbo/1-1200w.webp") }
        .slide_2 .slide_media { background-image: url("/_/images/slides/jumbo/2-1200w.webp") }
        .slide_3 .slide_media { background-image: url("/_/images/slides/jumbo/3-1200w.webp") }
        .slide_4 .slide_media { background-image: url("/_/images/slides/jumbo/4-1200w.webp") }
        .slide_5 .slide_media { background-image: url("/_/images/slides/jumbo/5-1200w.webp") }
        .slide_6 .slide_media { background-image: url("/_/images/slides/jumbo/6-1200w.webp") }
        .slide_7 .slide_media { background-image: url("/_/images/slides/jumbo/7-1200w.webp") }
        .slide_8 .slide_media { background-image: url("/_/images/slides/jumbo/8-1200w.webp") }
        .slide_9 .slide_media { background-image: url("/_/images/slides/jumbo/9-1200w.webp") }
        .slide_10 .slide_media { background-image: url("/_/images/slides/jumbo/10-1200w.webp") }

        .slide-placeholder { visibility: hidden; }

        .slide_media {
            position: relative;
        }

    }

	.no-webp & {
		@include media-breakpoint-up(md) {
			.slide_1 .slide_media { background-image: url("/_/images/slides/jumbo/1-1200w.jpg") }
			.slide_2 .slide_media { background-image: url("/_/images/slides/jumbo/2-1200w.jpg") }
			.slide_3 .slide_media { background-image: url("/_/images/slides/jumbo/3-1200w.jpg") }
			.slide_4 .slide_media { background-image: url("/_/images/slides/jumbo/4-1200w.jpg") }
			.slide_5 .slide_media { background-image: url("/_/images/slides/jumbo/5-1200w.jpg") }
			.slide_6 .slide_media { background-image: url("/_/images/slides/jumbo/6-1200w.jpg") }
			.slide_7 .slide_media { background-image: url("/_/images/slides/jumbo/7-1200w.jpg") }
			.slide_8 .slide_media { background-image: url("/_/images/slides/jumbo/8-1200w.jpg") }
			.slide_9 .slide_media { background-image: url("/_/images/slides/jumbo/9-1200w.jpg") }
			.slide_10 .slide_media { background-image: url("/_/images/slides/jumbo/10-1200w.jpg") }
		}

	}

}