.form_quote {

	.form_group { margin-bottom: 1%; }

	@include media-breakpoint-up(md) {
		
		.form_phone,
		.form_email,
		.form_name,
		.form_company {
			float: left;
			width: 49.5%;
		}

		.form_email,
		.form_company { float: right; }

	}


}