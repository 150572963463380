.block {
	padding: 45px 15px;
	&:not(.content, .block_content, .page_title, .site_info) 
	.row {
		align-items: center;
		justify-content: center;
	}
}

.block_title {
	text-align: center;
}

	.block-title {
		@extend %h2;
		margin-top: 0;
		font-weight: $heading-weight-bold;
	}

.block_main {
	> *:last-child { margin-bottom: 0; }
}

.block_more {
	text-align: center;
}