.form_jobs {

	.form_section {
		background-image: linear-gradient(
			to bottom,
			transparent 5px,
			rgba($white,0.7) 45px
		);
	}

	@include media-breakpoint-up(md) {

		.form_group { margin-bottom: 1%; }
		
		.section-subtitle { clear: both; }

		.form_fname,
		.form_lname,
		.form_phone,
		.form_email,
		.form_dob,
		.form_position,
		.form_start_date {
			float: left;
			width: 49.5%;
		}

		.form_lname,
		.form_email,
		.form_start_date { float: right; }

		.address_city,
		.address_state {
			float: left;
		}

		.address_city { width: 49.5%; margin-right: 1%; }
		.address_state { width: 24.25%; margin-right: 1%; }
		.address_zipcode { width: 24.25%; float: right; }


	}


}