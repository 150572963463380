.block_services {
	padding-top: 25px;
}

		.service_item {

			.item_title {
				@extend %h4;
				margin-bottom: 5px;
				line-height: 1.8;
				a {
					@include transition(color ease-in-out 300ms);
					display: inline-block;
					text-decoration: none;
					vertical-align: middle;
					white-space: nowrap;
					> * { vertical-align: middle; }
					&:hover {
						@include transition(color ease-in-out 300ms);
						color: theme(primary, base);
					}
				}
				b {
					font-weight: $heading-weight-bold;
				}
			}

			.icon-link-go {
				display: inline-block;
				width: (32 * 0.5) * 1px; 
				height: (19.2 * 0.5) * 1px;
				transform: rotate(-90deg);
				fill: $color-primary;
			}

			p { line-height: 1.625em; }

		}