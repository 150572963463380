//.address {}

	.address-street {
		&:after {
			content: ",";
		}
	}

.banner-address {
	margin-bottom: 0;
	@include font-size(15);
	line-height: 1em;
}