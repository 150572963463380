.coverage {

	font-family: $font-family-sans-serif;

	.coverage_title {
		margin-top: 0;
		@include font-size(20);
	}

	.coverage_county,
	.coverage_city {
		@include font-size(16);
		line-height: 1.325em;
	}

	&.block {

		padding: 30px 15px;
		.wrap > *:last-child { margin-bottom: 0; }

		@include media-breakpoint-up(lg) {
			.wrap { @include make-col(10); text-align: center; }
		}

		@include media-breakpoint-up(xl) {
			.wrap { @include make-col(9); }
		}

	}

}