.banner_menu {

	padding: 0;
	@include font-size(14);
	font-weight: $body-weight-bold;


	.menu > .menu_item {
	
	$mm_space: 1.5;
	$mm_count: 5;
	$mm_diff: $mm_count - 1;
	$mm_calc: $mm_diff * $mm_space;

		display: block;
		float: left;
		width: ((100-($mm_calc))/$mm_count) * 1%;
		text-align: center;

		&:not(:last-child) { margin-right: $mm_space * 1%; }

		&.open {
			> .menu_link {
				background: theme(highlight, base);
			}
		}

		&.active {
			> .menu_link {
				text-shadow: none;
				background: transparent !important;
				color: theme(secondary, base);
			}
		}

		> .menu_link {
			padding: 15px;
			font-weight: $body-weight-bolder;
			text-shadow: 0 1px 1px rgba(black, 0.2);
			border-radius: 4px;
			background: linear-gradient(
				to top,
				theme(secondary, base),
				theme(secondary, light) 50%,
				theme(primary, base) 50%,
				theme(primary, light)
			);
			background-size: 100% 200%;
			background-position: top left;
			&:hover {
				background-position: bottom left;
			}
		}

	}

	.menu_link { color: $white; }

}
