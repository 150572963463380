.review {

	p { margin: 0; }

	cite {
		display: block;
		margin-top: 5px;
		font-family: $font-family-sans-serif;
		font-style: normal;
		line-height: 1em;
		img {
			display: inline-block;
			width: 100px;
		}
		small {
			display: block;
			margin-top: 3px;
			font-size: 100%;
		}
	}

	.icon-stars {
		width: 130px;
		height: 24px;
	}

}