/*.block_article {

	.attachment-post-thumbnail {
		@include media-breakpoint-up(md) {
			float: right;
			margin: 0 0 10px 10px;
			width: 45%;
		}
	}

}*/

p.comment-form-url {
	display: none;
}

p.form-submit .submit {
	@extend %btn_secondary;
	@extend %btn--sm;
	font-size: 1rem;
}

.comments-area {
	margin-top: 2em;
	padding-top: 2em;
	border-top: 1px solid #ccc;
}

.comments-title {
	margin: 0 0 1em;
	font-family: $font-family-sans-serif;
	font-weight: 600;
}

	.comments-list {
		@extend %list_unstyled;
		ol { @extend %list_unstyled; }
		> .comment {
			margin-bottom: 2em;
			padding: 2em;
			background-color: $white;
			border: 1px solid #ccc;
			.children > li:not(:last-child) { margin-bottom: 1em; }
			.children .children > li:nth-child(odd) { background-color: #fafafa; }
		}
		.comment-respond {
			padding: 1em;
			background-color: #fafafa;
		}
	}

		.comment {
			padding: 1em 2em 0.5em;
			//border: 1px solid cyan;
		}

			.comment-body {
				padding: 0 1em 1em;
				//border: 1px solid red;
			}
				.comment-content {
					p:last-of-type { margin-bottom: 0; }
				}

		.comment.depth-2 { border-left: 2px solid #333;	}
		.comment.depth-3 { border-left: 2px solid #ccc; }

		.comment-meta {
			margin-bottom: 0.5em;
			padding-bottom: 0.5em;
			border-bottom: 1px solid #ccc;
		}

		.comment-metadata {
			a {
				font-size: 14px;
				text-decoration: none;
				cursor: default;
				color: theme(text, base);
			}
		}

		.comment-reply-link {
			@extend %btn_text;
			@extend %btn--sm;
			margin-top: 0.5em;
			font-size: 0.925rem !important;
			font-weight: 600 !important;
		}

	
	.comment-respond {
		margin: 0 0 1em;
		padding: 2em;
		border-radius: 5px;
		border: 1px solid #ccc;
		background-color: #fff;
		.comment-reply-title {
			margin-top: 0;
			font-size: 1.2rem;
			font-family: $font-family-sans-serif;
			font-weight: 800;
			text-transform: uppercase;
			small {
				display: block;
				margin-top: 0.5em;
				a {
					@extend %btn_danger;
					@extend %btn--sm;
					font-weight: 400 !important;
					text-transform: capitalize !important;
				}
			}
		}
	}