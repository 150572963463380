.block_announce {
	z-index: 0;
	padding: 18px 15px 15px;
	font-family: $font-family-sans-serif;
	border-top: 3px solid $white;
	border-bottom: 3px solid $white;
	background-color: theme(primary, dark);
	text-shadow: 0 1px 1px rgba(black,0.7);
	color: $white;

	p {
		margin-bottom: 5px;
		font-weight: $body-weight-bolder;
		line-height: 1em;
		text-align: center;
		text-transform: uppercase;
		&:last-child { margin-bottom: 0; }
	}

	@include media-breakpoint-up(md) {
		background-color: theme(secondary, base);
		.wrap {
			@include make-flex;
			align-items: center;
		}
		p {
			margin: 0;
			width: percentage(1/4);
		}
	}

}