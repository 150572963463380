.block_gallery {

		background-color: theme(primary, base);

		.gallery_main { @include make-col(10); }
		.gallery_more {
			@include make-col(2);
			padding: 0;
		}

		.gallery-button {
			text-indent: -9999px;
			width: 50px;
			height: 50px;
			border-radius: 100%;
			border: 2px solid $white;
			background-image: url("/_/images/icons/slide-white-next.png");
			background-repeat: no-repeat;
			background-position: center center;
			background-size: 15px;
			background-color: rgba(black, 0.5);
			transform: scale(0.9);
			&:hover {
				transform: scale(1);
				background-color: rgba(black, 0.8);
			}
		}

	@include media-breakpoint-up(md) {
		.gallery_main { @include make-col(11); }
		.gallery_more { @include make-col(1); }
		.gallery > a { margin-bottom: 0; }
	}

	@include media-breakpoint-up(lg) {}

}
